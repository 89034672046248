import { useEffect, useRef } from 'react';

/**
 * Hook to handle clicking outside of an element
 * @param {(evt: MouseEvent) => void} onClick The callback called when clicking outside
 * @returns {ref} The ref to attach to the element
 */
export default function useOnClickOutside<T>(
  onClick: (evt: MouseEvent) => void,
) {
  const elementRef = useRef<T | null>(null);

  useEffect(() => {
    const onClickHandler = (evt: MouseEvent) => {
      let target = evt.target;
      while (target as HTMLElement) {
        if (target === elementRef.current) {
          return;
        }
        target = (target as HTMLElement).parentElement;
      }

      onClick(evt);
    };

    window.addEventListener('click', onClickHandler, { passive: true });

    return () => {
      window.removeEventListener('click', onClickHandler);
    };
  }, [onClick]);

  return elementRef;
}
