import styled, { css, DefaultTheme } from 'styled-components/macro';

import checkImg from './assets/check.svg';

// Styled Components
const StyledSuccessMessage = styled.div<{ shown: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.successText};
  text-align: center;
  opacity: 0;
  ${(props) =>
    props.shown
      ? css`
          height: 26px;
          margin-bottom: 32px;
          opacity: 1;
          visibility: visible;
          transition: opacity 0.25s, height 0.25s, margin-bottom 0.25s;
        `
      : css`
          height: 0;
          margin-bottom: 0;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.25s, height 0.25s, margin-bottom 0.25s,
            visibility 0s 0.25s;
        `};

  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;

export type SuccessMessageProps = {
  message: string;
  shown?: boolean;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const SuccessMessage = ({
  message,
  shown = false,
  ...rest
}: SuccessMessageProps) => (
  <StyledSuccessMessage shown={shown} {...rest}>
    <img src={checkImg} alt="" />
    <span>{message}</span>
  </StyledSuccessMessage>
);

export default SuccessMessage;
