import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import PageController from 'components/PageController';

// Styled Components
const StyledResultsControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${(props) => props.theme.devices.mobile} {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const StyledResultsCount = styled.div`
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const StyledTopPageControls = styled.div`
  display: flex;
  flex-direction: row;

  margin-left: 10px;
`;

const StyledPageOfPages = styled.div`
  margin-right: 10px;
  font-size: ${(props) => props.theme.fontSizes.small};
  white-space: nowrap;
`;

export type ResultsControlsProps = {
  isLoading: boolean;
  loadingError?: any;
  page?: number;
  pages?: number;
  total?: number;
  onPageSelect: (page: number) => void;
  theme?: DefaultTheme;
};

// Component
const ResultsControls = ({
  isLoading,
  loadingError,
  page = 0,
  pages = 0,
  total = 0,
  onPageSelect,
}: ResultsControlsProps) => {
  const { t } = useTranslation();

  return (
    <StyledResultsControls>
      <StyledResultsCount>
        {total !== null && t('search:results_count', { count: total })}
        {total === null &&
          isLoading &&
          !loadingError &&
          t('search:loading_results')}
      </StyledResultsCount>
      <StyledTopPageControls>
        {pages !== null && pages !== 0 && (
          <StyledPageOfPages>
            {t('search:page_of_pages', { page, pages })}
          </StyledPageOfPages>
        )}
        <PageController
          page={page}
          pageCount={pages}
          onPageSelect={onPageSelect}
          directionsOnly
        />
      </StyledTopPageControls>
    </StyledResultsControls>
  );
};

export default ResultsControls;
