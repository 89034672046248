import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledSectionHeading = styled.h4`
  font-family: ${(props) => props.theme.fonts.main};
`;

export type SectionHeadingProps = {
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'h4'>;

// Component
const SectionHeading = ({ children, ...rest }: SectionHeadingProps) => (
  <StyledSectionHeading {...rest}>{children}</StyledSectionHeading>
);

export default SectionHeading;
