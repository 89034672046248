import CovidFilters from 'common/types/CovidFilters';

export const DEFAULT_FILTERS = {
  age: undefined,
  country: undefined,
  state: undefined,
  inClinicalTrial: undefined,
  previousClinicalTrial: undefined,
  inHospital: undefined,
  healthcareWorker: undefined,
  requireVentilator: undefined,
  haveImmuneDiseases: undefined,
  onChemotherapy: undefined,
  takingImmunosuppressants: undefined,
  breastfeeding: undefined,

  page: 1,
} as CovidFilters;
