import { MAPS_API_KEY } from 'common/constants';

const GEOCODE_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json';
// Defines level of location details returned, using locality to get city
// Can be set based on docs here: https://developers.google.com/maps/documentation/geocoding/intro
const GEOCODE_RESULT_TYPE = 'locality';
const LOCATION_API_URL = `${GEOCODE_API_URL}?key=${MAPS_API_KEY}&result_type=${GEOCODE_RESULT_TYPE}`;

const RESULT_STATUS = {
  OK: 'OK',
  ZERO_RESULTS: 'ZERO_RESULTS',
  OVER_QUERY_LIMIT: 'OVER_QUERY_LIMIT',
  REQUEST_DENIED: 'REQUEST_DENIED',
  INVALID_REQUEST: 'INVALID_REQUEST',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

const ADDRESS_TYPES = {
  CITY: 'locality',
  STATE: 'administrative_area_level_1',
  COUNTRY: 'country',
};

export type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

export type GeocodeResult = {
  address_components: AddressComponent[];
};

export type GeocodeResponse = {
  results: GeocodeResult[];
  status: string;
};

export type GeocodedLocation = {
  country: string | null;
  state: string | null;
  city: string | null;
};

/**
 * Get the city, state, country at a specified latitude and longitude
 * @param {number} latitude The locations latitude
 * @param {number} longitude The locations longitude
 */
async function reverseGeocode(
  latitude: number,
  longitude: number,
): Promise<GeocodedLocation | null> {
  const latLng = `${latitude},${longitude}`;
  const locationUrl = `${LOCATION_API_URL}&latlng=${latLng}`;

  // Get the location details from Googles geolocation API
  const addressResponse = await fetch(locationUrl);
  const { results = null, status = null } =
    (await addressResponse.json()) as GeocodeResponse;

  if (status !== RESULT_STATUS.OK) {
    throw new Error(`Failed to get address details, status: ${status}`);
  }

  if (!results || results.length < 1) {
    return null;
  }

  // Extract the levels of location we require from the first result's components
  const addressDetails = results[0];
  let city: string | null = null;
  let state: string | null = null;
  let country: string | null = null;

  addressDetails.address_components.forEach((component) => {
    const { long_name: longName = null, types = [] } = component;

    if (types.includes(ADDRESS_TYPES.CITY)) {
      city = longName;
    } else if (types.includes(ADDRESS_TYPES.STATE)) {
      state = longName;
    } else if (types.includes(ADDRESS_TYPES.COUNTRY)) {
      country = longName;
    }
  });

  return {
    country,
    state,
    city,
  };
}

/**
 * Get the user's location
 */
export async function getLocation(): Promise<GeocodedLocation> {
  if (!navigator.geolocation) {
    throw new Error('Geolocation not supported');
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      async (location) => {
        const { coords: { latitude = null, longitude = null } = {} } = location;

        if (!latitude || !longitude) {
          reject(new Error('Failed to get latitude or longitude'));
          return;
        }

        const address = await reverseGeocode(latitude, longitude);

        if (!address) {
          reject(new Error('Unable to determine location'));
          return;
        }

        resolve(address);
      },
      (error) => {
        reject(error);
      },
      {
        enableHighAccuracy: false,
      },
    );
  });
}
