import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { DefaultTheme } from 'styled-components/macro';

import { getCovidTrial } from 'common/api/covidTrials';

import TrialPage from 'components/TrialPage';

// Styled Components
// None for now, using shared TrialPage

export type RouteMatchParams = {
  id?: string;
};

export type TrialCovidProps = {
  theme?: DefaultTheme;
};

// Component
// Need to include props to support StyledComponents useTheme
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TrialCovid = (props: TrialCovidProps) => {
  const match = useRouteMatch<RouteMatchParams>();

  const {
    params: { id = null },
  } = match;

  const loadTrial = () => {
    if (!id) {
      throw new Error('Missing ID');
    }
    return getCovidTrial(id);
  };

  return <TrialPage loadTrial={loadTrial} {...props} />;
};

export default TrialCovid;
