export default {
  'about_covid': 'For COVID-19, we are helping to empower the patient, caregiver and practitioner by connecting them with all the trial options available all over the world to help make a faster and easier enrollment process.',
  'clinical_trials_heading': 'Help discover new ways to detect, manage and treat COVID-19. Find worldwide clinical trials in minutes.',
  'find_covid_trials': 'Find COVID-19 trials',
  'how_it_works': 'How it works',
  'how_it_works_details': `
    <0>
      <0>
        Our platform is meant to connect you to clinical trials happening in the world.
        We encourage you to speak to the investigators running the trial for all of the
        benefits and risks to participating or
      </0>
      <1>click here.</1>
    </0>
    <1>
      The investigator's contact information is provided when you click into the trial of your choice.
    </1>
    <2>
      The content on this website is not intended to be a substitute for professional medical advice,
      diagnosis, or treatment. Please seek the advice of your physician or other qualified health
      provider with any questions you may have regarding a medical condition. 
    </2>
  `,
  'need_help': 'Need help?',
  'need_help_details': `
    <0>
      Please use the contact chat box or contact us button to get in touch with feedback or for any
      help with using our site.
    </0>
    <1>
      We'll get back to you as soon as possible. For any trial specific related question or concerns,
      we ask that you get in touch with the site investigator directly or with your healthcare practitioner.
    </1>
  `,
  'world_health_organization': 'World Health Organization',
  'who_description': 'We are updating trials as quickly as possible but this may not be an exhaustive list of all available trials. Please visit World Health Organization for all up to date information.',
  'launch_website': 'Launch Website',

  'ready_to_start': 'Ready to start looking for COVID-19 trials?',
  'stay_in_touch': `
    <0>If you'd like to stay in touch about trials as they become available, you can submit your email</0>
    <1>here</1>
    <2>.</2>
  `,
};
