/**
 * Helper function to compare filter values
 * Handles array values of primitive types
 * @param {string|number|bool|array|null} filterA The first filter to compare
 * @param {string|number|bool|array|null} filterB The second filter to compare
 */
export default function areFiltersEqual(filterA: any, filterB: any) {
  if (Array.isArray(filterA)) {
    if (!Array.isArray(filterB) || filterA.length !== filterB.length) {
      return false;
    }

    // Sort and check value by value
    const sortedValueA = [...filterA].sort();
    const sortedValueB = [...filterB].sort();
    for (let i = 0; i < sortedValueA.length; i++) {
      if (sortedValueA[i] !== sortedValueB[i]) {
        return false;
      }
    }

    return true;
  }

  return filterA === filterB;
}
