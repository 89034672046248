import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { TrialLocationLink } from 'common/types/TrialLocation';

import SectionSubheading from 'components/SectionSubheading';

// Styled Components
const StyledResourceLinks = styled.section``;

const StyledSectionSubheading = styled(SectionSubheading)<{
  isMainSection: boolean;
}>`
  ${(props) =>
    !props.isMainSection &&
    css`
      color: ${props.theme.colors.subheading};
      font-size: ${props.theme.fontSizes.h6};
    `}
`;

const StyledResourceList = styled.ul<{ isMainSection: boolean }>`
  ${(props) =>
    !props.isMainSection &&
    css`
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: ${props.theme.fontSizes.small};
    `};
`;

export type ResourceLinksProps = {
  links: TrialLocationLink[];
  isMainSection?: boolean;
  theme?: DefaultTheme;
};

// Component
const ResourceLinks = ({
  links,
  isMainSection = false,
}: ResourceLinksProps) => {
  const { t } = useTranslation();

  return (
    <StyledResourceLinks>
      <StyledSectionSubheading isMainSection={isMainSection}>
        {t('trial:resource_links')}
      </StyledSectionSubheading>
      <StyledResourceList isMainSection={isMainSection}>
        {links.map(({ description, url }) => (
          <li key={url}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {description || url}
            </a>
          </li>
        ))}
      </StyledResourceList>
    </StyledResourceLinks>
  );
};

export default ResourceLinks;
