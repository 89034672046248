import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledAuthPage = styled.main`
  ${(props) => props.theme.paddedContent}

  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  margin-top: 25px;

  @media ${(props) => props.theme.devices.tablet} {
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const StyledContent = styled.div`
  width: 100%;
  max-width: 400px;
  margin-right: 32px;

  @media ${(props) => props.theme.devices.tablet} {
    margin-right: 0;
  }
`;

const StyledAside = styled.aside`
  @media ${(props) => props.theme.devices.tablet} {
    display: none;
  }

  img {
    max-width: 100%;
  }
`;

export type AuthPageProps = {
  accentImage: string;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'main'>;

const AuthPage = ({ accentImage, children, ...rest }: AuthPageProps) => (
  <StyledAuthPage {...rest}>
    <StyledContent>{children}</StyledContent>
    <StyledAside>
      <img src={accentImage} alt="" />
    </StyledAside>
  </StyledAuthPage>
);

export default AuthPage;
