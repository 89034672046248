import { useRef } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

import checkImg from './assets/check.svg';

// Styled Components
const StyledCheckbox = styled.span``;

const StyledCheckboxBox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  text-align: center;

  background-color: ${(props) => props.theme.rawColors.white};
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  box-shadow: 0 0 10px -5px ${(props) => props.theme.rawColors.black20};
  border-radius: 5px;

  cursor: pointer;
`;

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;

  &:focus + ${StyledCheckboxBox} {
    border-color: ${(props) => props.theme.colors.active};
  }
`;

export type CheckboxProps = {
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'input'>;

const Checkbox = ({ checked, ...rest }: CheckboxProps) => {
  const checkboxRef = useRef<HTMLInputElement | null>(null);

  return (
    <StyledCheckbox>
      <StyledInput
        checked={checked}
        ref={checkboxRef}
        type="checkbox"
        {...rest}
      />
      <StyledCheckboxBox
        onClick={(evt) => {
          evt.preventDefault();
          checkboxRef.current?.dispatchEvent(
            new MouseEvent('click', { bubbles: true }),
          );
        }}
      >
        {checked && <img src={checkImg} alt="" />}
      </StyledCheckboxBox>
    </StyledCheckbox>
  );
};

export default Checkbox;
