/**
 * Get the first value in an array
 * @param {array} arr The array to get the first value from
 * @param {any} defaultValue The default value if the array is null or empty
 */
export default function getFirstValue(
  arr: any[],
  defaultValue: any = undefined,
) {
  return arr && arr.length > 0 ? arr[0] : defaultValue;
}
