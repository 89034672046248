import styled, { css, DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

// Styled Components
const StyledPasswordField = styled.span<{
  disabled?: boolean;
  hasError?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  background-color: #ffffff;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 5px;
  box-shadow: 0 0 10px -5px ${(props) => props.theme.rawColors.black20};

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: opacity 0.4s;

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${props.theme.colors.error};
      color: ${props.theme.colors.error};
    `}

  &:focus-within {
    border-color: ${(props) => props.theme.colors.active};
  }
`;

const StyledInput = styled.input`
  padding: 0;
  border: none;

  &:focus {
    outline: none;
  }
`;

const StyledShowButton = styled.button`
  display: block;
  flex: 0 0 auto;
  width: 50px;
  padding: 0;
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.accentText};
  font-size: ${(props) => props.theme.fontSizes.xsmall};
  text-align: right;
`;

export type PasswordFieldProps = {
  disabled?: boolean;
  hasError?: boolean;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'input'>;

const PasswordField = ({
  disabled = false,
  hasError = false,
  ...rest
}: PasswordFieldProps) => {
  const { t } = useTranslation();
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  return (
    <StyledPasswordField disabled={disabled} hasError={hasError}>
      <StyledInput
        type={isPasswordVisible ? 'text' : 'password'}
        disabled={disabled}
        {...rest}
      />
      <StyledShowButton
        type="button"
        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
      >
        {isPasswordVisible
          ? t('common:hide_password')
          : t('common:show_password')}
      </StyledShowButton>
    </StyledPasswordField>
  );
};

export default PasswordField;
