import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledSectionSubheading = styled.h5`
  margin-bottom: 5px;
  font-family: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.colors.subheading};
`;

export type SectionSubheadingProps = {
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'h5'>;

// Component
const SectionSubheading = ({ children, ...rest }: SectionSubheadingProps) => (
  <StyledSectionSubheading {...rest}>{children}</StyledSectionSubheading>
);

export default SectionSubheading;
