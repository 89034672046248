import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { TrialLocationFacility } from 'common/types/TrialLocation';

// Styled Components
const StyledTitle = styled.h4`
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export type FacilityDetailsProps = {
  facility: TrialLocationFacility;
  isContentOnly?: boolean;
  theme?: DefaultTheme;
};

// Component
const FacilityDetails = ({
  facility,
  isContentOnly = false,
}: FacilityDetailsProps) => (
  <>
    {facility.name && (
      <StyledTitle as={isContentOnly ? 'div' : 'h4'}>
        {facility.name}
      </StyledTitle>
    )}
    {facility.address && (
      <address>
        {facility.address.city}
        ,&nbsp;
        {facility.address.state && `${facility.address.state}, `}
        {facility.address.country}
        {facility.address.zip && `, ${facility.address.zip}`}
      </address>
    )}
  </>
);

export default FacilityDetails;
