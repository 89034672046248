import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledSelectTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 40px;

  text-align: center;
`;

export type SelectTitleProps = {
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'h2'>;

// Component
const SelectTitle = ({ children, ...rest }: SelectTitleProps) => (
  <StyledSelectTitle {...rest}>{children}</StyledSelectTitle>
);

export default SelectTitle;
