import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledTextButton = styled.button`
  padding: 0;
  margin: 0;

  background-color: transparent;
  border: none;

  color: ${(props) => props.theme.colors.link};
  font-size: ${(props) => props.theme.fontSizes.small};
  text-decoration: underline;

  transition: color 0.4s;

  &:disabled {
    color: ${(props) => props.theme.colors.linkDisabled};
  }
`;

export type TextButtonProps = {
  theme?: DefaultTheme;
} & Omit<React.ComponentProps<'button'>, 'ref'>;

// Component
const TextButton = ({ children, ...rest }: TextButtonProps) => (
  <StyledTextButton {...rest}>{children}</StyledTextButton>
);

export default TextButton;
