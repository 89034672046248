import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import PageButton from 'components/PageButton';

import arrowLeftImg from './assets/icon-arrow_left.svg';
import arrowLeftDisabledImg from './assets/icon-arrow_left-disabled.svg';
import arrowRightImg from './assets/icon-arrow_right.svg';
import arrowRightDisabledImg from './assets/icon-arrow_right-disabled.svg';

// Styled Components
const StyledPageController = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 55px;
`;

const StyledPageButtons = styled.div`
  display: flex;
  flex-direction: row;

  margin-left: 10px;
  margin-right: 10px;
`;

const StyledPageButton = styled(PageButton)`
  margin-right: 5px;

  &:last-of-type {
    margin-right: 0;
  }
`;

const StyledActivePage = styled(StyledPageButton)`
  &:disabled {
    color: ${(props) => props.theme.colors.darkButtonText};
    background-color: ${(props) => props.theme.colors.darkButtonBackground};
    border-color: ${(props) => props.theme.colors.darkButtonBackground};
  }
`;

const StyledEllipsis = styled.div`
  padding-left: 5px;
  padding-right: 10px;
`;

export type PageControllerProps = {
  page: number;
  pageCount?: number;
  directionsOnly?: boolean;
  onPageSelect: (page: number) => void;
  theme?: DefaultTheme;
};

// Component
const PageController = ({
  directionsOnly = false,
  page,
  pageCount = 0,

  onPageSelect,
}: PageControllerProps) => {
  const { t } = useTranslation(['common', 'search']);

  // Show the first page, 2 pages before, the active page, 2 pages after, and the last page
  const minPage = Math.max(1, page - 2);
  const maxPage = Math.min(pageCount, minPage + 4);

  const pageButtons = [];
  for (let i = minPage; i <= maxPage; i++) {
    // The active page
    if (i === page) {
      pageButtons.push(
        <StyledActivePage key={`page-${i}`} disabled>
          {i}
        </StyledActivePage>,
      );
      // The first page
    } else if (i === minPage && minPage > 1) {
      pageButtons.push(
        <StyledPageButton key="page-1" onClick={() => onPageSelect(1)}>
          1
        </StyledPageButton>,
      );
      pageButtons.push(
        <StyledEllipsis key="ellipsis-1">
          {t('common:ellipsis')}
        </StyledEllipsis>,
      );
      // The last page
    } else if (i === maxPage && pageCount > maxPage) {
      pageButtons.push(
        <StyledEllipsis key="ellipsis-2">
          {t('common:ellipsis')}
        </StyledEllipsis>,
      );
      pageButtons.push(
        <StyledPageButton
          key={pageCount}
          onClick={() => onPageSelect(pageCount)}
        >
          {pageCount}
        </StyledPageButton>,
      );
      // All other pages
    } else {
      pageButtons.push(
        <StyledPageButton key={`page-${i}`} onClick={() => onPageSelect(i)}>
          {i}
        </StyledPageButton>,
      );
    }
  }

  const isFirstPage = page === 1 || !pageCount;
  const isLastPage = page === pageCount || !pageCount;

  return (
    <StyledPageController>
      <PageButton disabled={isFirstPage} onClick={() => onPageSelect(page - 1)}>
        <img
          src={isFirstPage ? arrowLeftDisabledImg : arrowLeftImg}
          alt={t('search:previous_page')}
        />
      </PageButton>
      {!directionsOnly && <StyledPageButtons>{pageButtons}</StyledPageButtons>}
      <PageButton disabled={isLastPage} onClick={() => onPageSelect(page + 1)}>
        <img
          src={isLastPage ? arrowRightDisabledImg : arrowRightImg}
          alt={t('search:previous_page')}
        />
      </PageButton>
    </StyledPageController>
  );
};

export default PageController;
