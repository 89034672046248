import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

// Styled Components
const StyledTrialResultsLoadingError = styled.h4`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 250px;

  font-family: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.colors.error};
  text-align: center;
`;

export type TrialResultsLoadingErrorProps = {
  theme?: DefaultTheme;
};

// Component
// Need to include theme prop to support StyledComponents useTheme
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TrialResultsLoadingError = (props: TrialResultsLoadingErrorProps) => {
  const { t } = useTranslation();

  return (
    <StyledTrialResultsLoadingError>
      {t('search:error_loading_trials')}
    </StyledTrialResultsLoadingError>
  );
};

export default TrialResultsLoadingError;
