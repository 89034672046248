import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import SelectTitle from 'components/SelectTitle';

// Styled Components
const StyledYesNoInput = styled.div``;

const StyledButtonContainer = styled.div`
  text-align: center;
`;

const StyledButton = styled.button<{ isSelected: boolean }>`
  width: 140px;
  padding: 16px;

  background-color: ${(props) => props.theme.rawColors.offWhite};
  border: 2px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 5px;

  &:nth-of-type(1) {
    margin-right: 40px;
  }

  ${(props) =>
    props.isSelected &&
    css`
      border-color: ${props.theme.colors.active};
    `}

  @media ${(props) => props.theme.devices.mobile} {
    width: 40%;
    max-width: 140px;

    &:nth-of-type(1) {
      margin-right: 20px;
    }
  }
`;

export type YesNoInputProps = {
  question: string;
  value?: boolean;
  onChange: (value: boolean) => void;
  theme?: DefaultTheme;
};

// Component
const YesNoInput = ({ question, value, onChange }: YesNoInputProps) => {
  const { t } = useTranslation();

  return (
    <StyledYesNoInput role="radiogroup">
      <SelectTitle>{question}</SelectTitle>
      <StyledButtonContainer>
        <StyledButton
          type="button"
          role="radio"
          isSelected={value === true}
          aria-checked={value === true}
          onClick={() => onChange(true)}
        >
          {t('common:yes')}
        </StyledButton>
        <StyledButton
          type="button"
          role="radio"
          isSelected={value === false}
          aria-checked={value === false}
          onClick={() => onChange(false)}
        >
          {t('common:no')}
        </StyledButton>
      </StyledButtonContainer>
    </StyledYesNoInput>
  );
};

export default YesNoInput;
