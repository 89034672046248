import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';

import Button from 'components/Button';

// Styled Components
const StyledInputWithButton = styled.div`
  position: relative;

  display: flex;
  max-width: 510px;
  width: 100%;
  border-radius: 5px;

  @media ${(props) => props.theme.devices.mobile} {
    flex-direction: column;
    max-width: 100%;
  }
`;

const StyledInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
`;

const StyledIcon = styled.img`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const StyledInput = styled.input<{ icon?: string }>`
  flex-grow: 1;
  margin: 0;
  ${(props) =>
    props.icon &&
    css`
      padding-left: 45px;
    `}
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 5px 0 0 5px;

  transition: opacity 0.4s;

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.active};
  }

  &:disabled {
    opacity: 0.5;
  }

  @media ${(props) => props.theme.devices.mobile} {
    border-radius: 5px;
  }
`;

const StyledButton = styled(Button)`
  min-width: 140px;
  border-radius: 0 5px 5px 0;

  @media ${(props) => props.theme.devices.mobile} {
    margin-top: 10px;
    border-radius: 5px;
  }
`;

export type InputWithButtonProps = {
  buttonText: string;
  className?: string;
  disabled?: boolean;
  inputIcon?: string;
  inputId?: string;
  inputType?: string;
  placeholder?: string;
  value: string;

  onChange: (value: string) => void;
  onSubmit: () => void;
  theme?: DefaultTheme;
};

// Component
const InputWithButton = ({
  buttonText,
  className,
  disabled = false,
  inputIcon,
  inputId,
  inputType,
  placeholder = '',
  value,

  onChange,
  onSubmit,
}: InputWithButtonProps) => {
  const onKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') {
      evt.preventDefault();
      onSubmit();
    }
  };

  return (
    <StyledInputWithButton className={className}>
      <StyledInputContainer>
        {inputIcon && <StyledIcon src={inputIcon} alt="" />}
        <StyledInput
          disabled={disabled}
          icon={inputIcon}
          id={inputId}
          placeholder={placeholder}
          type={inputType}
          value={value}
          onChange={(evt) => onChange(evt.target.value)}
          onKeyPress={onKeyPress}
        />
      </StyledInputContainer>
      <StyledButton onClick={onSubmit} disabled={disabled} dark>
        {buttonText}
      </StyledButton>
    </StyledInputWithButton>
  );
};

export default InputWithButton;
