import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import resources from './resources';

// TODO: In the future load translations based on user's locale
const currentLng = 'en';

i18n.use(initReactI18next).init({
  lng: currentLng,
  fallbackLng: 'en',

  resources,
});

export default i18n;
