import Checkbox from 'components/Checkbox';
import { CheckboxProps } from 'components/Checkbox/Checkbox';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledCheckboxOption = styled.label`
  display: flex;
  align-items: center;
`;

const StyledLabelText = styled.span`
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) => props.theme.fontSizes.small};
  }
`;

export type CheckboxOptionProps = {
  label: React.ReactNode;
  theme?: DefaultTheme;
} & CheckboxProps;

const CheckboxOption = ({ className, label, ...rest }: CheckboxOptionProps) => (
  <StyledCheckboxOption className={className}>
    <Checkbox {...rest} />
    <StyledLabelText>{label}</StyledLabelText>
  </StyledCheckboxOption>
);

export default CheckboxOption;
