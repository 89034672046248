import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledPageButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  height: 25px;

  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.lightButtonBorder};
  border-radius: 3px;

  color: ${(props) => props.theme.colors.lightButtonText};
  font-size: ${(props) => props.theme.fontSizes.xsmall};

  &:disabled {
    border-color: ${(props) => props.theme.rawColors.darkGray};
    color: ${(props) => props.theme.colors.linkDisabled};
  }
`;

export type PageButtonProps = {
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'button'>;

// Component
const PageButton = ({ children, ...rest }: PageButtonProps) => (
  <StyledPageButton {...rest}>{children}</StyledPageButton>
);

export default PageButton;
