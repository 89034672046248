import styled, { DefaultTheme } from 'styled-components/macro';
import PasswordField, {
  PasswordFieldProps,
} from 'components/PasswordField/PasswordField';
import LabeledField from 'components/LabeledField';

// Styled Components
const StyledPasswordField = styled(PasswordField)`
  box-sizing: border-box;
  width: 100%;
`;

export type LabeledPasswordFieldProps = {
  hasError?: boolean;
  label: string;
  isRequired?: boolean;
  theme?: DefaultTheme;
} & PasswordFieldProps;

const LabeledPasswordField = ({
  children,
  hasError = false,
  label,
  isRequired = false,
  ...rest
}: LabeledPasswordFieldProps) => (
  <LabeledField hasError={hasError} label={label} isRequired={isRequired}>
    <StyledPasswordField hasError={hasError} {...rest}>
      {children}
    </StyledPasswordField>
  </LabeledField>
);

export default LabeledPasswordField;
